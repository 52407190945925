import { type QueryKey, queryOptions } from "@tanstack/react-query";
import type { AdminHostPool, AdminHostPoolCreateBody, AdminHostPoolUpdateBody } from "@warrenio/api-spec/spec.oats.gen";
import { mapFromEntries, mergeMaps } from "@warrenio/utils/collections/maps";
import { atomFamily } from "jotai/utils";
import { type ApiClient, getResponseData } from "../../modules/api/apiClient.ts";
import { atomAllLocationsQuery } from "../../modules/location/atomAllLocationsQuery.ts";
import { jsonEncodedBody } from "../../utils/fetchClient.ts";
import { adminMutation } from "../../utils/query/adminMutation.ts";
import { atomFromStandardQueryOptions } from "../../utils/query/queryAtom.ts";

export interface Params {
    location: string;
}

export interface AdminHostPoolWithType extends AdminHostPool, Params {
    $type: "host_pool";
}

export type Response = Map<AdminHostPool["uuid"], AdminHostPoolWithType>;

export function getQueryKey(params?: Params): QueryKey {
    return params == null
        ? ["base-operator/admin/host_pool/list"]
        : ["base-operator/admin/host_pool/list", params.location];
}

function hostPoolFromData(data: AdminHostPool, location: string): AdminHostPoolWithType {
    return { ...data, $type: "host_pool", location };
}

export function getSingleQuery(apiClient: ApiClient, params: Params) {
    return queryOptions({
        queryKey: getQueryKey(params),
        queryFn: async ({ signal }): Promise<Response> => {
            const { location } = params;
            const response = getResponseData(
                await apiClient.GET("/{location}/base-operator/admin/host_pool/list", {
                    signal,
                    params: { path: { location } },
                }),
            );
            return mapFromEntries(response, (pool: AdminHostPool) => [pool.uuid, hostPoolFromData(pool, location)]);
        },
    });
}

export const adminHostPoolQueryAtom = atomFamily((location: string) =>
    atomFromStandardQueryOptions(getSingleQuery, { location }),
);

export const adminAllHostPoolsQueryAtom = atomAllLocationsQuery(adminHostPoolQueryAtom, (results) =>
    mergeMaps(results),
);

//#region Mutations
export const hostPoolCreateMutation = adminMutation((apiClient) => ({
    entity: "host pool",
    operation: "create",
    requestFn: async ({ body, location }: { body: AdminHostPoolCreateBody; location: string }) =>
        await apiClient.POST("/{location}/base-operator/admin/host_pool", {
            params: { path: { location: location } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: getQueryKey,
}));

export const hostPoolUpdateMutation = adminMutation((apiClient) => ({
    entity: "host pool",
    operation: "update",
    requestFn: async ({ body, location, uuid }: { body: AdminHostPoolUpdateBody; location: string; uuid: string }) =>
        await apiClient.PATCH("/{location}/base-operator/admin/host_pool/{uuid}", {
            params: { path: { location: location, uuid: uuid } },
            ...jsonEncodedBody,
            body,
        }),
    invalidateQueryKey: getQueryKey,
}));

export const hostPoolDeleteMutation = adminMutation((apiClient) => ({
    entity: "host pool",
    operation: "delete",
    requestFn: async ({ uuid, location }: { uuid: string; location: string }) =>
        await apiClient.DELETE("/{location}/base-operator/admin/host_pool/{uuid}", {
            params: { path: { location: location, uuid: uuid } },
        }),
    invalidateQueryKey: getQueryKey,
}));

export const defaultVmsMutation = adminMutation((apiClient) => ({
    entity: "Host Pool",
    operation: "Set Pool Preselection",
    requestFn: async ({ uuid, location }: { uuid: string; location: string }) =>
        await apiClient.PUT("/{location}/base-operator/admin/host_pool/{uuid}/default_for_vms", {
            params: { path: { location: location, uuid: uuid } },
        }),
    invalidateQueryKey: getQueryKey,
}));

export const defaultHostsMutation = adminMutation((apiClient) => ({
    entity: "Host Pool",
    operation: "Set Default Pool for Hosts",
    requestFn: async ({ uuid, location }: { uuid: string; location: string }) =>
        await apiClient.PUT("/{location}/base-operator/admin/host_pool/{uuid}/default_for_hosts", {
            params: { path: { location: location, uuid: uuid } },
        }),
    invalidateQueryKey: getQueryKey,
}));
//#endregion Mutations
